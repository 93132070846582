import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    text: '#291945',
    primary: '#F6941D',
    secondary: '#291945',
    accent: 'orange',
    neutral: '#191127',
    background: '#F0F4F9',
    divider: 'rgba(41, 25, 69, 0.1)',
    overlay: 'rgba(41, 25, 69, 0.5)',
  },
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'inherit',
  },
  breakpoints: ['40em', '64em', '85.375em', '120em'], // 640, 1024, 1366, 1920
  fontSizes: [16, 18, 22, 24, 32, 40, 50, 68, 96],
  // TODO something ain't right with these font weights
  fontWeights: {
    body: 400,
    normal: 400,
    semibold: 500,
    bold: 600,
    heading: 600,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.3,
  },
  buttons: {
    primary: {
      fontWeight: 'bold',
      borderRadius: '100px',
      padding: '7px 21px 7px 21px',

      '&:disabled': {
        opacity: .4,
      },
    },
  },
  text: {
    neutral: {
      primary: {
        color: '#A49FB2',
      },
      accent: {
        color: 'white',
      },
    },
  },
  link: {
    textDecoration: 'none',
  },
  forms: {
    input: {
      border: 0,
      padding: 16,
      color: '#000',
      fontFamily: 'inherit',
      backgroundColor: '#fff',

      '&::placeholder': {
        color: 'inherit',
      },

      '&:disabled': {
        opacity: .4,
      },
    },
    textarea: {
      border: 0,
      padding: 16,
      color: '#000',
      fontFamily: 'inherit',
      backgroundColor: '#fff',

      '&::placeholder': {
        color: 'inherit',
      },

      '&:disabled': {
        opacity: .4,
      },
    },
  },
  zIndices: [0, 10, 20, 30],
}

export default theme
