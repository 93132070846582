import galite from 'ga-lite'
import Cookies from 'universal-cookie'
import App from './src/App'

const cookies = new Cookies()
const googleAnalyticsCookieName = 'ormo-gdpr-google-analytics'
let isTrackingInitialized = false

const isTrackingEnabled = () =>
  process.env.GA_TRACKING_ID && cookies.get(googleAnalyticsCookieName) === 'true'

const initializeTracking = () => {
  if (!isTrackingInitialized) {
    galite('create', process.env.GA_TRACKING_ID, 'auto')
    galite('set', 'anonymizeIp', true)

    isTrackingInitialized = true
  }
}

export const onRouteUpdate = () => {
  if (isTrackingEnabled()) {
    initializeTracking()
    galite('send', 'pageview')
  }
}

export const wrapRootElement = App
