module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ormo.hu","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ORMO","short_name":"ormo","start_url":"/","background_color":"#fff","theme_color":"#ff9900","display":"minimal-ui","icon":"src/content/images/identity/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"27e0ac86774bcd4a95394fef72279feb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
