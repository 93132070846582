import React, { FC, ReactNode } from 'react'

import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import theme from './theme'
import Layout from './ui/Layout'
import backgroundImageUrl from './content/images/bg.png'

type Props = {
  element: ReactNode
}

const App: FC<Props> = ({ element }) => {
  return (
    <>
      <Global
        styles={css`
          *,
          *::after,
          *::before {
            box-sizing: inherit;
          }

          body {
            margin: 0;
            font-size: 18px;
            box-sizing: border-box;
            background-repeat: repeat-y;
            background-position-y: -25vh;
            background-position-x: center;
            background-size: 150vmax auto;
            background-color: ${theme.colors.background};
            background-image: url(${backgroundImageUrl});
          }

          body, button {
            font-family: Poppins, sans-serif;
          }

          img {
            border: 0;
            max-width: 100%;
            height: auto;
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <Layout>
          {element}
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default App
